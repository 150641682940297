<template>
  <div>
    <b-modal
      size="lg"
      v-model="show"
      title="Product Price Changes"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :body-text-variant="bodyTextVariant"
      :footer-bg-variant="footerBgVariant"
      :footer-text-variant="footerTextVariant"
    >
     <div class="row">
          <div class="col-sm-12">
            <el-table :data="changePrice" border element-loading-text="Loading" style="width: 100%" v-loading="loading" 
            @selection-change="handleSelectionChange" ref="multipleTable">
              <el-table-column
                type="selection"
                width="55">
              </el-table-column>
              <el-table-column label="SKU"  width="100">
                <template slot-scope="scope">
                  {{scope.row.sku}}
                </template>
              </el-table-column>               
              <el-table-column label="Product Name"  :min-width="200">
                <template slot-scope="scope">
                  {{scope.row.name}}
                </template>
              </el-table-column> 
               <el-table-column label="Old Price" width="100" align="center">
                <template slot-scope="scope">
                  <span>${{scope.row.old}}</span>
                </template>
              </el-table-column> 
               <el-table-column label="New Price" width="100" align="center">
                <template slot-scope="scope">
                  <span><b>${{scope.row.new}}</b></span>
                </template>
              </el-table-column>     
            </el-table>
          </div>
     </div>
      

      <template #modal-footer>
        <div class="w-100">
          <!-- <p class="float-left">Modal Footer Content</p> -->
          
         <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="createOrder()"
            v-if="api_url"
          >
            Confirm
          </b-button>
           <b-button
            variant="danger"
            size="sm"
            class="float-right mr-5"
            @click="show = false"           
          >
            Cancel
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>


<script>


import Form from "@/core/services/api.form.services";
import { globalEvents } from "@/utils/globalEvents";
import {
  searchCustomer,
  getCustomerAddress,
  getWebsite,
  getOrderTerm,
  searchProduct,
  getTax,
  getShippingMethod,
  getShippingCost,
  getDeliveryType, 
} from "@/api/order";
import {
  getAllCustomers,
  getCustomerCredit,
  getCustomerType,
  getSalesoutsidelist,
} from "@/api/customer";
import { getProductsForDropdown } from "@/api/product";
import createProduct from "@/components/CreateProduct/add.vue";
import createCustomer from "@/components/CreateCustomer/index.vue";
import CreateAddress from "@/components/CreateAddress/addAddress";
import CreateTerm from "@/components/CreateOrderTerm";
import { getAllActiveSites } from "@/api/website";
import createTax from "@/components/CreateTax/addTax.vue";
import UpdateAddress from "@/components/CreateAddress";
import CreateShipping from "@/components/DropDown/shippingMethod";
import DeliverType from "@/components/DropDown/deliveryType";
import {getQuotationDetails} from  "@/api/quotation";

export default {
  name: "add-order",
   props: {
    	changePrice: {
	      	type: Array,
	      	default() {
	        	return {}
	      	}
	    },
      quotation_id : {
        type: Number
      },
      quotation : {
        type: Object
      }
     
     },
  data() {
    return {
      loading: false,
      loadingCustomer: false,
       show: true,      
        variants: ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark'],
        headerBgVariant: 'light',
        headerTextVariant: 'dark',
        bodyBgVariant: 'light',
        bodyTextVariant: 'dark',
        footerBgVariant: 'secondary',
        footerTextVariant: 'dark',
      error: null,
      user: [],
      bill: true,
      ship: true,
      labelPosition: "right",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      shippingId : 1,
      create_form: 'order_create',
      customer_name:null,
      form: new Form({
        customer_id: null,
        customer_type: null,
        customer_typeId: null,
        created_date: new Date(new Date().toLocaleString('en', {timeZone: 'America/Toronto'})),
        delivery_date: new Date(new Date().toLocaleString('en', {timeZone: 'America/Toronto'})),
        billing_address_id: null,
        shipping_address_id: null,
        ship_via: null,
        sale_person: null,
        shiping_method: null,
        order_type: "Regular Order",
        order_by: null,
        type: null,
        term: null,
        sub_total: "$ 0.00",
        subTotal: 0,
        discount: "$ 0.00",
        credit_amount: 0,
        order_status: "pending",
        website_id: null,
        comments: null,
        tax_per: 13,
        tax_total: "$ 0.00",
        shipping: "$ 0.00",
        total: "$ 0.00",
        customer_po: null,
        project_name: null,
        ref_by: null,
        ct_discount: "$ 0.00",
        delivery_type: null,
        shipping_manual:"$ 0.00",
        adjustment_price :"$ 0.00",
        adjustment_type:null,
        order_taken_by:null,
        available_credit:null,
        paymentID:null,
        estimation_create_by:null,
        products: [],
        quotation_items:[],
      }),
      search: "",
      addresses: [],
      city_list: [],
      state_list: [],
      customer_list: [],
      product_list: [],
      active_website: [],
      product_price: [],
      billing_address: [],
      shipping_address: [],
      type_list: [],
      term_list: [],
      quantity: [],
      tax_list: [],
      deliveryType: [],
      ouside_salesperson: [],
      searchKeyword: "",
      logo_btn_text: null,
      submit_btn_text: "Save Estimation",
      title_text: "Create Estimation",
      api_url: "",
      response_msg: "Estimation created successfully.",
      shippingmethod: [],     
      priceType: [
        {
          id: 1,
          text: "Inside",
        },
      ],
     
      types: ["date"],
      options: {},
      show_product_error: false,
      show_product_qty_error: false,
      show_credit_error: false,
      user_credits: 0,
      balance_credit: null,
      current_product_index: null,
      credit_maxtotal: 0,
      ship_cost: 0,
      distance: 0,
      datelable: 'Delivery Date',
      multipleSelection:[]
    };
  },
  components: {},
  mounted() {
 
  },
  watch: {
  
    //    '$route' (to, from) {
    //    console.log(to, from);
    //  },
    search(value) {
      if (!value) {
        return;
      }
      //this.loading = true
      searchCustomer(value).then((response) => {
        this.customer_list = response.data.data;
        // this.loading = false
      });
      
     
      searchProduct(value,[]).then((response) => {
        this.product_price = response.data.data;
        // this.loading = false
      });
      this.getCustomerAddress();
    },
  },
  methods: {
     handleSelectionChange(val) {
        this.multipleSelection = val;       
        this.multipleSelection.map((check_prod)=>{
          let index = this.quotation.quotation_items.findIndex(x=>x.id == check_prod.id);
          this.setProductPrice(index);
        })
      },
      getProductPrice(){ 
        this.product_price = [];
          this.quotation.quotation_items.map((es_product)=>{ 
          this.product_price.push(es_product.product_item);
           
           this.show = true;       
        }); 
        
    },
    getShipped() {
      this.ship = true;
      if (this.form.shiping_method == "Store Pickup") {
        this.form.shipping_address_id = "";
        this.form.ship_via = "";
        this.shipping_address = [];
      }
    },
    getType() {
      this.loading = true;
      getCustomerType().then((response) => {
        this.type_list = response.data.data;
        this.loading = false;
      });
    },
    getTax() {
      this.loading = true;
      getTax().then((response) => {
        this.tax_list = response.data.data;
        this.loading = false;
      });
    },
    getSalesoutsidelist() {
      this.loading = true;
      getSalesoutsidelist().then((response) => {
        this.ouside_salesperson = response.data.data;        
        this.loading = false;
      });
    },
    shippedChange(method) {
      //this.addShipping();
      if (method != "Store Pickup") {
          this.shipping_address = [];
          
            this.addresses.map((shipping) => {  
                         
              if (shipping.type == "shipping") {                           
                  this.shipping_address.push(shipping);
                   
                  this.ship = false;
                  if(this.shipping_address.length == 1){
                    this.form.shipping_address_id = shipping.id
                    this.addShipping();  
                  }
               if(this.shipping_address.length>1){
                  this.shipping_address = [];
                  this.form.shipping_address_id = ''
                  this.ship = true;
               }
              }
            });
        
        if (this.form.shipping_address_id > 0) {
          this.ship = false;
        }
        if (method == "Free Shipping") {
          this.distance = 0;
          this.ship_cost = 0;
          this.form.shipping = "0.00";
          if (this.form.subTotal > 0) {
            this.calculateTotal();
          }
        }
      } else {
        (this.form.shipping_address_id = ""), (this.distance = 0);
        this.ship_cost = 0;
        this.form.shipping = "0.00";
        this.shipping_address = [];
        this.ship = false;
        if (this.form.subTotal > 0) {
          this.calculateTotal();
        }
      }
    },
    getShippingMethod() {
      this.loading = true;
      getShippingMethod().then((response) => {       
        this.shippingmethod = response.data.data;
        this.loading = false;
      });
    },
    UpdateAddress(billing) {
      this.$modal.show(
        UpdateAddress,
        {
          customer_id: this.form.customer_id,
          address: billing[0],
          label: {
            button: "Update",
            title: "Update Address",
          },
        },
        {
          draggable: true,
          transition: "pop-out",
          overflow: "auto",
          height: "auto",
          clickToClose: false,
          adaptive: true,
        }
      );
    },
    setValueNull() {
      this.value_ = null;
      this.form.customer_typeId = "";
      this.form.customer_type = "";
      this.form.website_id = "";
      this.getAllCustomers();
    },
    taxClear() {
      this.tax_per = 0;
    },
    priceTypeClear(index) {
      this.form.quotation_items[index].price_type = null;
    },
    shippingClear() {
      this.form.shipping_address_id = null;
      this.distance = 0;
      this.form.shipping = "0.00";
      this.ship_cost = 0;
      this.calculateTotal();
    },

    addTableRow: function () {
      this.counter++;
      this.tableRows.push("Table Row " + this.counter);
    },
    availableQuantity(product_id) {
      let total_stock = this.getStockQuantity(product_id);
      if (this.$route.params.orderId) {
        this.quantity.map((value) => {
          if (value.product_id == product_id) {
            total_stock =
              parseInt(value.quantity) +
              parseInt(this.getStockQuantity(product_id));
          }
        });
      }
      return total_stock;
    },
    availableCredit() {
      let total_credit = parseInt(this.user_credits);
      if (this.form.sub_total > 0) {
        total_credit = parseInt(this.form.sub_total);
        if (this.form.sub_total >= total_credit) {
          this.show_credit_error = true;
        }
      }
      return total_credit;
    },
    isExist(product_id) {
      for (var i = 0; i < this.form.quotation_items.length; i++) {
        if (this.form.quotation_items[i].product_id == product_id) {
          return true;
        }
      }
      return false;
    },
    reset() {
      this.form.customer_typeId = "";
      this.form.customer_type = "";
      this.form.website_id = "";
      this.form.billing_address_id = "";
      this.form.shipping_address_id = "";
      this.billing_address = [];
      this.shipping_address = [];
      this.product_price = [];
      this.form.quotation_items = [];
      this.form.sub_total = '$ 0.00';
      this.form.discount = '$ 0.00';
      this.form.tax_total = '$ 0.00';
      this.form.total = '$ 0.00';
      this.bill = true;
      this.ship = true;
      this.user_credits = "";
      this.$refs["form"].resetFields();
    },
    addressLabel(address) {
      let formattedAddress = "";
      if (address.company_name) {
        formattedAddress += address.company_name + ", ";
      }
      if (address.address_line_1) {
        formattedAddress += address.address_line_1 + ", ";
      }
      if (address.address_line_2) {
        formattedAddress += address.address_line_2 + ", ";
      }
      if (address.city) {
        formattedAddress += address.city_details.city_name + ", ";
      }
      if (address.state) {
        formattedAddress += address.state_details.state_name + ", ";
      }
      if (address.post_code) {
        formattedAddress += address.post_code + ", ";
      }
      return formattedAddress;
    },
    setProductPrice(index) {
      let customerType = this.form.quotation_items[index].customer_type;
      let priceType = this.form.quotation_items[index].price_type;
      let pid = this.form.quotation_items[index].product_id;
      let unit_price = 0;
      if(this.form.quotation_items[index].price_change == 0){
        unit_price = this.getProductPriceById(pid, customerType,index);
      }else{
        const extraDiscount = this.form.quotation_items[index].extra_discount
        unit_price = this.getProductChangePriceById(pid,customerType,extraDiscount);
      } 
    
      let inside_price = 0;
      let quantity = parseInt(
        this.form.quotation_items[index].quantity
          ? this.form.quotation_items[index].quantity
          : 0
      );
      let extra_discount = 0;
      let tax_percentage = 0;
      let unit_tax_amount = 0;
      let tax_id = parseInt(this.form.quotation_items[index].tax_id);
      let stock = this.getStockQuantity(pid);
      let wholeQuantity = this.getWholeQuantity(pid);
      let wholePrice = this.getWholePrice(pid);
      let wholeDiscount = 0;
      if (this.$route.params.orderId) {
        this.quantity.map((value) => {
          if (value.product_id == pid) {
            stock = parseInt(this.getStockQuantity(pid));
          }
        });
      }

      if (pid) {
        if (quantity == 0) {
          if (stock == 0 || stock == null ) {
            const h = this.$createElement;
            this.$notify({
              message: h(
                "i",
                {
                  style: "color: red",
                },
                "Product Out of Stock"
              ),
              offset: 200,
              type: "warning",
            });
          }
          this.form.quotation_items[index].quantity = parseInt("1");
          quantity = parseInt("1");
        }

        
      }
      if(customerType == 4){
      if (wholeQuantity) {
        if (quantity >= wholeQuantity) {
          if (unit_price > wholePrice) {
            wholeDiscount = quantity * (unit_price - wholePrice);
          }
          unit_price = wholePrice;
        }
      }
      }

      if (tax_id) {
        tax_percentage = this.getTaxAmountByTaxId(tax_id, index);
      }
      if (tax_percentage > 0) {
        unit_tax_amount = this.calculateTaxAmount(unit_price, tax_percentage);
      }
      if (priceType == 1) {
        inside_price = this.getInsidePrice(pid);
      }
      let inside = 0;
      if (quantity > 0) {
        inside = (unit_price * inside_price) / 100;
      }
      this.form.quotation_items[index].product_id = pid
      this.form.quotation_items[index].customer_discount = wholeDiscount + quantity * this.product_discount;
      this.form.quotation_items[index].stock_quantity = stock ? stock : "0";
      this.form.quotation_items[index].total_qtyprice = (quantity * unit_price).toFixed(2);
      this.form.quotation_items[index].tax_list = this.getProductTaxListById(pid);
      this.form.quotation_items[index].type = this.getProductType(pid);
      this.form.quotation_items[index].unit_price = (unit_price - extra_discount).toFixed(2);
      this.form.quotation_items[index].regular_price = this.getRegularPrice(pid).toFixed(2);
      this.form.quotation_items[index].inside_per = inside_price;
      this.form.quotation_items[index].inside_product_price = quantity * inside;
      this.form.quotation_items[index].tax_amount = quantity * unit_tax_amount;
      this.form.quotation_items[index].total_price = (quantity * unit_price - quantity * extra_discount).toFixed(2);
      this.form.quotation_items[index].price = parseInt(this.form.quotation_items[index].quantity) * unit_price;
      this.form.quotation_items[index].discount = quantity * extra_discount + wholeDiscount + quantity * this.product_discount;
      this.form.quotation_items[index].total = quantity * unit_price  - quantity*extra_discount;
      this.calculateTotal();
    },
   getProductPriceById(product_id, customerType) {
      let productPrice = 0;
      let Productdiscount = 0;
      if (product_id) {
        this.product_price.map((product) => {
          //console.log(index+"--"+product_id+"---"+product.id);
          if (product_id == product.id) {
            if (product.regular_price != "" && product.regular_price != null) {
              if (customerType == 1) {
                if (product.contractor_price != "" && product.contractor_price != null && product.contractor_price !=0) {                 
                  productPrice = parseFloat(product.contractor_price);
                  if (product.special_price != "" && product.special_price != null && product.special_price !=0) {
                    if(product.contractor_price > product.special_price){
                      productPrice = parseFloat(product.special_price);
                      Productdiscount = product.contractor_price - product.special_price;
                    }
                  }
                  
                } else {
                  productPrice = parseFloat(product.regular_price);
                  if (product.special_price != "" && product.special_price != null && product.special_price !=0) {
                    if(product.regular_price > product.special_price){
                      productPrice = parseFloat(product.special_price);
                      Productdiscount = product.regular_price - product.special_price
                    }
                  }
                }
              } else if (customerType == 2) {
                if (product.dealer_amount != "" && product.dealer_amount != null && product.dealer_amount !=0) {                
                  productPrice = parseFloat(product.dealer_amount);
                  if (product.special_price != "" && product.special_price != null && product.special_price !=0) {
                    if(product.dealer_amount > product.special_price){
                      productPrice = parseFloat(product.special_price);
                      Productdiscount = product.dealer_amount - product.special_price
                    }
                  }
                } else {
                  productPrice = parseFloat(product.regular_price);
                  if (product.special_price != "" && product.special_price != null && product.special_price !=0) {
                    if(product.regular_price > product.special_price){
                      productPrice = parseFloat(product.special_price);
                      Productdiscount = product.regular_price - product.special_price
                    }
                  }
                }
              } else {
                productPrice = parseFloat(product.regular_price);
                if (product.special_price != "" && product.special_price != null && product.special_price !=0) {
                  if(product.regular_price > product.special_price){
                      productPrice = parseFloat(product.special_price);
                      Productdiscount = product.regular_price - product.special_price
                    }
                }
              }
            } else {
              productPrice = parseFloat(product.cost_price);
              if (product.special_price != "" && product.special_price != null && product.special_price !=0) {
                if(product.cost_price > product.special_price){
                      productPrice = parseFloat(product.special_price);
                      Productdiscount = product.cost_price - product.special_price
                    }
              }
            }
          }
        });
      }
      this.product_discount = Productdiscount;     
      return productPrice;
    },
    getProductChangePriceById(product_id,customerType,extra_discount) {
      let productPrice = 0;
      let Productdiscount = 0;
      this.price_loading = true;
      if (product_id) {
        this.product_price.map((product) => {
          if (product_id == product.id) {
            if (product.regular_price != "" && product.regular_price != null) {
                const product_price = product.regular_price - product.regular_price * (extra_discount / 100);               
                productPrice = parseFloat(product_price);
                Productdiscount = product.regular_price - productPrice;
                if (product.special_price != "" && product.special_price != null && product.special_price != 0) {
                  if (productPrice > product.special_price) {
                    productPrice = parseFloat(product.special_price);
                    Productdiscount = productPrice - product.special_price;
                  }
                }
                if(customerType == 2){
                const product_price = product.cost_price - product.cost_price * (extra_discount / 100);               
                productPrice = parseFloat(product_price);
                Productdiscount = product.cost_price - productPrice;
                if (product.special_price != "" && product.special_price != null && product.special_price != 0) {
                  if (productPrice > product.special_price) {
                    productPrice = parseFloat(product.special_price);
                    Productdiscount = productPrice - product.special_price;
                  }
                }
                }
            }else {
              const product_cost_price = product.cost_price - product.cost_price * (extra_discount / 100);               
              productPrice = parseFloat(product_cost_price);
              if (
                product.special_price != "" &&
                product.special_price != null &&
                product.special_price != 0
              ) {
                if (productPrice > product.special_price) {
                  productPrice = parseFloat(product.special_price);
                  Productdiscount = productPrice - product.special_price;
                }
              }
            }
            this.price_loading = false;
          }
        });
      }
      this.product_discount = Productdiscount;
      return productPrice;
    },
    getRegularPrice(product_id) {
      let productPrice = 0;

      if (product_id) {
        this.product_price.map((product) => {
          //console.log(index+"--"+product_id+"---"+product.id);
          if (product_id == product.id) {
            if (product.regular_price != "" || product.regular_price == null) {
              productPrice = parseFloat(product.regular_price);
            } else {
              productPrice = parseFloat(product.cost_price);
            }
          }
        });
      }
      return productPrice;
    },

    getProductDiscount(product_id, customerType) {
      let productPrice = 0;

      if (product_id) {
        this.product_price.map((product) => {
          //console.log(index+"--"+product_id+"---"+product.id);
          if (product_id == product.id) {
            if (customerType == 1) {
              if (product.contractor_price) {
                if (product.regular_price > product.contractor_price) {
                  productPrice = parseFloat(
                    product.regular_price - product.contractor_price
                  );
                }
              }
            } else if (customerType == 2) {
              if (product.dealer_amount) {
                if (product.regular_price > product.dealer_amount) {
                  productPrice = parseFloat(
                    product.regular_price - product.dealer_amount
                  );
                }
              }
            } 
          }
        });
      }

      return productPrice;
    },

    getWholeQuantity(pid) {
      let whole_quantity = 0;
      if (pid) {
        this.product_price.map((product) => {
          if (product.id == pid) {
            whole_quantity = product.whole_quantity;
          }
        });
      }
      return whole_quantity;
    },
    getWholePrice(pid) {
      let whole_price = 0;
      if (pid) {
        this.product_price.map((product) => {
          if (product.id == pid) {
            whole_price = parseFloat(product.whole_amount);
          }
        });
      }
      return whole_price;
    },
    getInsidePrice(pid) {
      let inside_price = "";
      if (pid) {
        this.product_price.map((product) => {
          if (product.id == pid) {
            inside_price = parseFloat(product.inside_per);
          }
        });
      }
      return inside_price;
    },
    getStockQuantity(product_id) {
      let stock = 0;
      if (product_id) {
        this.product_price.map((product) => {
          if (product_id == product.id) {
            stock = product.stock_quantity;
          }
        });
      }
      return stock;
    },
    getProductType(product_id) {
      let type = 0;
      if (product_id) {
        this.product_price.map((product) => {
          if (product_id == product.id) {
            type = product.unit;
          }
        });
      }
      return type;
    },
    getTaxAmountByTaxId(tax_id, pindex) {
      let tax_amount = 0;
      if (tax_id) {
        this.form.quotation_items[pindex].tax_list.map((tax) => {
          if (tax_id == tax.tax_id) {
            tax_amount = parseFloat(tax.tax_amount);
          }
        });
      }
      return tax_amount;
    },
    calculateTaxAmount(unit_product_price, tax_percentage) {
      let tax_amount = 0;
      if (tax_percentage > 0) {
        tax_amount = (unit_product_price * tax_percentage) / 100;
      }
      return tax_amount;
    },
    getProductTaxListById(product_id) {
      let tax_list = 0;
      if (product_id) {
        this.product_price.map((product) => {
          if (product_id == product.id) {
            tax_list = product.tax_list;
          }
        });
      }
      return tax_list;
    },
    searchCustomer(q) {
      if (q !== "") {
        //this.loadingCustomer = true;
        searchCustomer(q)
          .then((response) => {
            //this.loadingCustomer = false;
            this.customer_list = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            //this.loadingCustomer = false;
            this.customer_list = [];
          });
      } else {
        this.customer_list = [];
      }
    },
    searchProduct(q) {
      if (q !== "") {
        //this.loadingProduct = true;
        var productid={product :this.getProductId(this.form.quotation_items)};
        searchProduct(q,productid)
          .then((response) => {
            //this.loadingProduct = false;
            this.product_price = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            //this.loadingProduct = false;
           
            this.product_price = [];
          });
      } else {
         this.getProductsForDropdown();
        //this.product_price = [];
      }
    },
    getProductId(products){
      var productid=[];
       for(var product in products){
         if(products[product]['product_id']){
           productid.push(products[product]['product_id']);
         }
      }
      return productid;
    },
    customFilter(item, queryText) {
      const textOne = item.first_name ? item.first_name.toLowerCase() : "";
      const textTwo = item.last_name ? item.last_name.toLowerCase() : "";
      const searchText = queryText ? queryText.toLowerCase() : "";
      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    productNameFilter(item, queryText) {
      const textOne = item.description ? item.description.toLowerCase() : "";
      const searchText = queryText ? queryText.toLowerCase() : "";
      return textOne.indexOf(searchText) > -1;
    },
    productSkuFilter(item, queryText) {
      const textOne = item.sku ? item.sku.toLowerCase() : "";
      const searchText = queryText ? queryText.toLowerCase() : "";
      return textOne.indexOf(searchText) > -1;
    },
    convert(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    },
    createOrder: function () {
     
      this.loading = true;
      this.form.delivery_date = this.convert(this.form.delivery_date);
      this.form.created_date = this.convert(this.form.created_date);
      //console.log(this.form);
      this.form
        .post(this.api_url)
        .then((response) => {
          if (response.status) {
            globalEvents.$emit('priceChange','changes');
            this.loading = false;
            this.show = false;
           // this.$showResponse("success", response.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    createCustomer() {
      this.$modal.show(
        createCustomer,
        {},
        {
          draggable: true,
          transition: "pop-out",
          overflow: "auto",
          height: "inherit",
          clickToClose: false,
          adaptive: true,
          width: "950px",
        }
      );
    },
    createProduct() {
      this.$modal.show(
        createProduct,
        {
          //customer_id: row.customer_address[0].customer_id
        },
        {
          draggable: true,
          transition: "pop-out",
          overflow: "auto",
          height: "inherit",
          clickToClose: false,
          adaptive: true,
          width: "1000px",
        }
      );
    },
    CreateShipping() {
      this.$modal.show(
        CreateShipping,
        {},
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
    CreateDeliveryType() {
      this.$modal.show(
        DeliverType,
        {},
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
    createAddress(val) {
      var titleName = "Shipping Address";
      if (val == "1") {
        titleName = "Billing Address";
      }
      this.$modal.show(
        CreateAddress,
        {
          customer_id: this.form.customer_id,
          address_type: val,
          label: {
            button: "Create",
            title: titleName,
          },
        },
        {
          draggable: true,
          transition: "pop-out",
          overflow: "auto",
          height: "auto",
          clickToClose: false,
          adaptive: true,
          width: "600px",
        }
      );
    },
    getAllCustomers() {
      this.loading = true;
      getAllCustomers().then((response) => {
        //console.log(response);
        this.customer_list = response.data.data.data;
        this.loading = false;
      });
    },
    clearAdjustprice(){
      this.form.adjustment_price = '$ 0.00'
      this.calculateTotal();
    },
    getDeliveryType() {
      this.loading = true;
      getDeliveryType().then((response) => {
        this.deliveryType = response.data.data;
        this.loading = false;
      });
    },
    changeItem: function changeItem(rowId, event) {
      this.selected =
        "rowId: " + rowId + ", target.value: " + event.target.value;
    },
    getOrderTerm() {
      this.loading = true;
      getOrderTerm().then((response) => {
        this.term_list = response.data.data;
        this.loading = false;
      });
    },
    addProduct() {
      let customerType = null;
      this.customer_list.map((customer) => {
        if (this.form.customer_id == customer.id) {
          if (customer.customer_type) {
            customerType = customer.type_id;
          }
        }
      });

      this.form.quotation_items.push({
        product_id: null,
        quantity: null,
        unit_price: 0,
        regular_price: 0,
        tax_id: null,
        tax_amount: null,
        total_price: 0,
        stock_quantity: 0,
        discount: 0,
        tax_list: [],
        extra_discount: 0,
        total_qtyprice: 0,
        customer_type: customerType,
        price_type: null,
        inside_per: "",
        total: 0,
        customer_discount: 0,
      });
    },
    removeProduct(index) {
      this.form.quotation_items.splice(index, 1);
      //this.setProductPrice(index);
      this.calculateTotal();
    },
    shippingcostCalculation(km, cost) {
      let shiiping_cost = 0;

      if (km > 1 && km < 7 && cost <= 500) {
        shiiping_cost = 19.99;
      } else if (km >= 8 && km <= 15 && cost <= 1000) {
        shiiping_cost = 29.99;
      } else if (km >= 16 && km <= 22 && cost <= 1500) {
        shiiping_cost = 39.99;
      } else if (km >= 23 && km <= 30 && cost <= 2000) {
        shiiping_cost = 59.99;
      } else if (km >= 31 && km <= 40 && cost <= 3000) {
        shiiping_cost = 69.99;
      } else if (km >= 41 && km <= 50 && cost <= 3500) {
        shiiping_cost = 89.99;
      } else if (km >= 51 && km <= 60 && cost <= 4500) {
        shiiping_cost = 99.99;
      } else if (km >= 61 && km <= 70 && cost <= 7000) {
        shiiping_cost = 150.0;
      } else {
        shiiping_cost = 0.0;
      }
      return shiiping_cost;
    },

    calculateTotal() {
      let sub_total = 0;
      let taxPer = this.form.tax_per ? this.form.tax_per : 0;
      let discount = 0;
      let ct_discount = 0;
      let credit = this.form.credit_amount ? this.form.credit_amount : 0;
      let ship_cost = 0;
      let inside_price = 0;
      if(this.form.quotation_items == 'Store Pickup'){
        this.form.products.map((product) => {
          product.inside_product_price = 0;
         // product.inside_per = '';
          product.price_type = null;
        });
      }
      this.form.quotation_items.map((product) => {
        sub_total = sub_total + product.total;
        discount = discount + product.discount;
        ct_discount = ct_discount + product.customer_discount;
        inside_price = inside_price + product.inside_product_price;
      });
      let shippingCharge = 0;
      this.form.sub_total = "$ " + parseFloat(sub_total).toFixed(2);
      this.form.subTotal = sub_total + discount;
      this.form.discount = "$ " + parseFloat(discount).toFixed(2);
      if (this.distance > 0) {
        ship_cost = this.shippingcostCalculation(this.distance, sub_total);
      }
     
      if(ship_cost == 0){
        this.shippingId = 0
        this.form.shipping  = '$ 0.00'; 
        var shippingValue =  this.form.shipping_manual?this.form.shipping_manual:0.00; 
         shippingCharge = parseFloat(shippingValue);
         this.form.shipping_manual = "$ " + parseFloat(shippingValue).toFixed(2);
          
        }else{
           this.shippingId = 1
           this.form.shipping_manual = '$ 0.00';
           this.form.shipping = "$ " + parseFloat(ship_cost).toFixed(2);
       
          shippingCharge = this.shippingcostCalculation(this.distance, sub_total);
        }
      let tax = ((sub_total + shippingCharge + inside_price) * taxPer) / 100;
      let taxcharge = 0;
      this.form.inside_price_total = "$ " + parseFloat(inside_price).toFixed(2);
      this.form.tax_total = "$ " + parseFloat(tax).toFixed(2);
      this.form.ct_discount = "$ " + parseFloat(ct_discount).toFixed(2);
      this.form.total =
        "$ " + parseFloat(sub_total - credit + taxcharge).toFixed(2);
      var TaxValue = this.form.tax_total.replace(/\$|,/g, "")
        ? this.form.tax_total.replace(/\$|,/g, "")
        : 0.0;
      taxcharge = parseFloat(TaxValue);

      let adjustment = 0;  
        if(this.form.adjustment_type ==1){
          
          var adjust1 = this.form.adjustment_price.replace(/\$|,/g, '')?this.form.adjustment_price.replace(/\$|,/g, ''):0.00;
          var adt = parseFloat(adjust1).toFixed(2).match(/^-?[0-9]*[.][0-9]+$/);
          if(adt != null){
            this.form.adjustment_price = "$ "+ parseFloat(adjust1).toFixed(2);
            adjustment = parseFloat(adjust1);
            this.form.total ="$ "+ parseFloat((sub_total + shippingCharge - credit + taxcharge ) + adjustment).toFixed(2);
          }else{
            this.form.adjustment_price = "$ 0.00";
          }          
        }else{
           var adjust2 = this.form.adjustment_price.replace(/\$|,/g, '')?this.form.adjustment_price.replace(/\$|,/g, ''):0.00;
           var adj2 = parseFloat(adjust2).toFixed(2).match(/^-?[0-9]*[.][0-9]+$/);
           if(adj2 != null){
              adjustment = parseFloat(adjust2);
              this.form.adjustment_price = "$ "+ parseFloat(adjust2).toFixed(2);
              this.form.total ="$ "+ parseFloat((sub_total + shippingCharge - credit + taxcharge ) - adjustment).toFixed(2);
           }else{
             this.form.adjustment_price = "$ 0.00";
           }
         
          }
    },
    getCustomerAddress() {
      this.form.billing_address_id = "";
      this.form.shipping_address_id = "";
      this.billing_address = [];
      this.shipping_address = [];
      this.product_price = [];
      this.form.quotation_items = [];
      this.distance = "";
      this.form.sub_total = "$ 0.00";
      this.form.discount = "$ 0.00";
      this.form.tax_total = "$ 0.00";
      this.form.shipping = "$ 0.00";
      this.form.shipping_manual = "$ 0.00";
       this.form.ct_discount = "$ 0.00";
      this.form.total = "$ 0.00";
      this.bill = true;
      this.ship = true;
      this.user_credits = "";
      if (this.form.customer_id) {
        //this.loading = true
        this.form.customer_typeId = "";
        this.form.customer_type = "";
        this.customer_list.map((customer) => {
          if (this.form.customer_id == customer.id) {
            if (customer.customer_type) {
              this.form.customer_typeId = customer.type_id;
              this.form.customer_type = customer.customer_type.type_name;
            }
          }
        });
        getCustomerAddress(this.form.customer_id).then((response) => {
          //this.loading = false
          this.addresses = response.data.data;
           this.shipping_address = [];
           this.billing_address = [];
            this.addresses.map((shipping) => {  
                         
              if (shipping.type == "shipping") {               
                  this.shipping_address.push(shipping);
                  this.ship = false;
                  if(this.shipping_address.length == 1){
                    this.form.shipping_address_id = shipping.id
                  }
               if(this.shipping_address.length>1){
                  this.shipping_address = [];
                  this.form.shipping_address_id = ''
                  this.ship = true;
               }
              }
             
               if (shipping.type == "billing") {
                  this.addShipping();
                  this.billing_address.push(shipping);
                  this.bill = false;
                   if(this.billing_address.length == 1){
                    this.form.billing_address_id = shipping.id
                  }
                   if(this.billing_address.length>1){
                  this.billing_address = [];
                  this.bill = true;
                  this.form.billing_address_id = ''
               }
               
               }
            });
          
        });
        this.getWebsite();
        this.getCustomerCredit();
        this.getProductsForDropdown();
      }
    },
    getCustomerCredit() {
      this.loading = true;
      getCustomerCredit(this.form.customer_id).then((response) => {
        this.loading = false;
        if (response.data.data) {
          this.user_credits = response.data.data.approved_credit;
          this.balance_credit = response.data.data.balance_showing;
          this.available_credit = response.data.data.available_credit
        }
      });
    },
    getWebsite() {
      this.loading = true;
      getWebsite(this.form.customer_id).then((response) => {
        this.form.website_id = parseInt(response.data.data.website_id);
        this.loading = false;
        if (!this.$route.params.estimate_id) {
          //this.addProduct();
        }
      });
    },
    getProductsForDropdown() {
      this.loading = true;
      getProductsForDropdown().then((response) => {
        this.loading = false;
        this.product_price = response.data.data;
      });
    },
    getAllActiveSites() {
      this.loading = true;
      getAllActiveSites().then((response) => {
        this.active_website = response.data.data;
        this.loading = false;
      });
    },
    createTax() {
      this.$modal.show(
        createTax,
        {},
        {
          draggable: true,
          transition: "pop-out",
          overflow: "auto",
          height: "auto",
          clickToClose: false,
          adaptive: true,
          width: "400",
        }
      );
    },
    CreateTerm() {
      this.$modal.show(
        CreateTerm,
        {},
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
    addBilling() {
      let billing_id = this.form.billing_address_id;
      this.billing_address = [];
      this.addresses.map((billing) => {
        if (billing.type == "billing") {
          if (billing_id == billing.id) {
            this.billing_address.push(billing);
            this.bill = false;
          }
        }
      });
    },
    addShipping() {
      let shipping_id = this.form.shipping_address_id;
      let store_id = this.form.website_id;
      this.distance = 0;
      if (this.form.shipping_address_id) {
        getShippingCost(store_id, shipping_id).then((response) => {
          if (this.form.shiping_method != "Free Shipping") {
            this.distance = response.data.km;
            if (this.form.subTotal > 0) {
              this.calculateTotal();
            }
          }
        });
      }
      this.shipping_address = [];
      this.addresses.map((shipping) => {
        if (shipping.type == "shipping") {
          if (shipping_id == shipping.id) {
            this.shipping_address.push(shipping);
            this.ship = false;
          }
        }
      });
    },
    
   
  },
  
  created() {
    this.getTax();
    this.getType();
    this.getShippingMethod();
    this.getSalesoutsidelist();  
    let QuotationId = this.quotation_id;     
    
    if (parseInt(QuotationId) > 0) {
      
      this.submit_btn_text = "Update";
      this.title_text = "Edit Estimation";
      this.action = "update";
      this.response_msg = "Estimation updated successfully.";
      getQuotationDetails(QuotationId).then((response) => {       
        this.api_url = "quotation/edit/" + response.data.data.id;
        this.form = new Form(response.data.data);
        this.customer_name = response.data.data.customer.first_name + ' '+(response.data.data.customer.last_name != null?response.data.data.customer.last_name:'');
        this.addShipping();       
        this.quantity = response.data.data.sorder_items;
        this.form.quotation_items = response.data.data.quotation_items;
        this.billing_address.push(response.data.data.billing_address_order);
        if (this.form.shiping_method != "Store Pickup") {
          this.shipping_address.push(response.data.data.shipping_address_order);
        }
         this.searchProduct();
        this.form.total = "$" + parseFloat(response.data.data.total).toFixed(2);
        this.form.sub_total = "$" + parseFloat(response.data.data.sub_total).toFixed(2);

        this.form.discount = "$" + parseFloat(response.data.data.discount).toFixed(2);
        this.form.adjustment_type = response.data.data.adjustment_type == 1 ? 'Increment' : 'Decrement';
        this.form.adjustment_price = "$" + parseFloat(response.data.data.adjustment_price).toFixed(2);
        if(response.data.data.shipping != 0){
          this.shippingId = 1
          this.form.shipping = "$" + parseFloat(response.data.data.shipping).toFixed(2);
        }else{
          this.shippingId = 0
          this.form.shipping_manual = "$" + parseFloat(response.data.data.shipping_manual).toFixed(2);
        }
        this.form.ct_discount = "$" + response.data.data.ct_discount;
        this.form.tax_total = "$" + response.data.data.tax_total;
        this.bill = false;
        this.ship = false;
        this.getWebsite();
        getCustomerAddress(this.form.customer_id).then((response) => {
          this.loading = false;
          this.addresses = response.data.data;

        });
        this.getCustomerCredit();
        this.getOrderTerm();
        this.getProductPrice();
        //this.getProductsForDropdown();
      });
    }
    //this.getStateList();
    //this.getCityList();
    this.getAllCustomers();
    // this.getAllproductlists();
    this.getAllActiveSites();
    this.getOrderTerm();
    this.getDeliveryType();
   
  },
  
  computed: {
    shippingAddresses() {
      return this.addresses.filter((addr) => {
        return addr.type == "shipping";
      });
    },
    billingAddresses() {
      return this.addresses.filter((addr) => {
        return addr.type == "billing";
      });
    },
  },
};
</script>